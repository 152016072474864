function photoGallerySlider(sliderNode) {
    const sliderContainer = sliderNode.getElementsByClassName('slider-viewport')[0];
    if (sliderContainer === undefined || !sliderContainer) {
        console.log('photoGallerySlider: no sliderContainer found by class "slider-viewport"')
        return false;
    }
    const prevButtonContainer = sliderNode.getElementsByClassName('prev')[0];
    const nextButtonContainer = sliderNode.getElementsByClassName('next')[0];
    if (prevButtonContainer === undefined || !prevButtonContainer) {
        console.log('mainSlider: no prevButtonContainer found by class "prev"');
        return false;
    }
    if (nextButtonContainer === undefined || !nextButtonContainer) {
        console.log('mainSlider: no nextButtonContainer found by class "next"');
        return false;
    }
    const sliders = sliderContainer.getElementsByClassName('slide');
    const sliderNavs = sliderNode.getElementsByClassName('navs')[0];
    if (sliderNavs === undefined || !sliderNavs) {
        console.log('mainSlider: no sliderNavs found by class "navs"');
        return false;
    }

    $(sliderContainer).slick({
        slidesToShow: 1,
        speed: 300,
        slidesToShow: 1,
        centerMode: true,
        variableWidth: true,
        arrows: false,
        infinite: true,
        dots: false,
        nextArrow: nextButtonContainer,
        prevArrow: prevButtonContainer,
        appendDots: sliderNavs,
        dotsClass: "custom-nav",
        responsive: [
            {
                breakpoint: 1279,
                settings: {

                }
			},
            {
                breakpoint: 1023,
                settings: {

                }
			},
            {
                breakpoint: 767,
                settings: {

                }
			},
    	]
	});
}


document.addEventListener('DOMContentLoaded', function() {
    const photoGallerySliderNodes = document.getElementsByClassName('photogallery-slider');
    for(let i = 0; i < photoGallerySliderNodes.length; i++) {
        let photoGallerySliderNode = photoGallerySliderNodes.item(i);
        const photoGallerySliderObj = new photoGallerySlider(photoGallerySliderNode);
    }
});
