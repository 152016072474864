import indexStyles from '../sass/index.scss';
let commons = require('./utils/commons');
import photoGallerySliderJS from './includes/photogallery_slider.js';
import customSelectJS from './includes/custom_select.js';
import qaDropdownJS from './includes/qa_dropdown.js';
import item1Img from '../img/item-1.png';
import item2Img from '../img/item-2.png';
import item3Img from '../img/item-3.png';
import item4Img from '../img/item-4.png';
import item5Img from '../img/item-5.png';
import usageBgImg from '../img/usage-bg.jpg';
import gallery1Img from '../img/gallery-1.jpg';
import mapImg from '../img/map.jpg';
